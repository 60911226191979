import React, {useRef,useEffect} from "react"
import { Link } from "gatsby"
import * as noisejs from "noisejs"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const cnv = useRef()
  useEffect(() => {
    const ctx = cnv.current.getContext('2d')
    const a = 2 * Math.PI / 6
    const r = 50
    const speed = 2
    const noise = new noisejs.Noise(Math.random())
    const bgColor = '#0e0e22'
    const lineColor = '255,255,255'
    const visibleArea = 300
    let delta = 0
    let mousePos = [0,0]

    function resizeCanvas() {
      cnv.current.width = window.innerWidth
      cnv.current.height = window.innerHeight
    }

    function setup() {
      resizeCanvas()
      window.addEventListener('resize',resizeCanvas,false)
      window.addEventListener('mousemove', (e) => {mousePos = [e.clientX,e.clientY]},false)
    }

    function drawHex(origin,index) {
      let alpha = Math.abs(Math.sin(noise.simplex2(origin[0],origin[1]) + delta) * Math.cos(noise.simplex2(origin[0],origin[1]) + delta))
      if (Math.abs(mousePos[0] - origin[0]) < visibleArea && Math.abs(mousePos[1] - origin[1]) < visibleArea) {
        let distance = Math.sqrt(
            Math.pow(Math.abs(mousePos[0] - origin[0]),2) +
            Math.pow(Math.abs(mousePos[1] - origin[1]),2)
        )
        alpha *= 1 - (distance / visibleArea)
      } else {
        alpha = 0
      }
      
      let radius = r * Math.abs(Math.sin(noise.simplex2(origin[0],origin[1]) + delta))
      ctx.strokeStyle = `rgba(${lineColor},${alpha})`
      ctx.beginPath()
      for (let i = 0; i < 6; i++) {
        ctx.lineTo(origin[0] + radius * Math.cos(a * i), origin[1] + radius * Math.sin(a * i));
      }
      ctx.closePath()
      ctx.stroke()
    }

    function drawGrid(width, height) {
      let index = 0
      for (let y = r; y + r * Math.sin(a) < height; y += r * Math.sin(a)) {
        for (let x = r, j = 0; x + r * (1 + Math.cos(a)) < width; x += r * (1 + Math.cos(a)), y += (-1) ** j++ * r * Math.sin(a)) {
          drawHex([x, y],index)
          index++
        }
      }
    }

    function draw() {
      ctx.fillStyle = bgColor
      ctx.fillRect(0,0,cnv.current.width,cnv.current.height)
      drawGrid(cnv.current.width,cnv.current.height)
      delta += speed / 1000
      requestAnimationFrame(draw)
    }

    setup()
    draw()
  }, cnv)
  return (
    <Layout>
      <SEO title="Home" />
      <main>
        <h1>Nathan Akrill</h1>
        <h2><b>Front-end developer</b> from <b>Bournemouth, UK</b></h2>
        <ul>
          <li><a href='https://blog.akrill.net'>Blog</a></li>
          <li><Link to='/work'>Work</Link></li>
          <li><a href='https://twitter.com/H___H___H___H'>Twitter</a></li>
          <li><a href='https://codepen.io/Bathansneard'>CodePen</a></li>
          <li><a href='https://art.akrill.net'>Art</a></li>
          <li><a href='https://www.polywork.com/nathanakrill'>Polywork</a></li>
        </ul>
        <article className='modal'>
          <h3>Work</h3>
          <p>My portfolio is not currently public, if you want to see my work then you can email me at: <a href='mailto:nathan.akrill@gmail.com'>nathan.akrill@gmail.com]</a>.</p>
        </article>
        <div className='modal__overlay'></div>
      </main>
      <canvas ref={cnv}></canvas>
      <figure className='character'>
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 189">
    <rect x="63" y="180" width="9" height="9" fill="#5e412c"/>
    <rect x="72" y="171" width="9" height="9" fill="#5e412c"/>
    <rect x="54" y="180" width="9" height="9" fill="#5e412c"/>
    <rect x="81" y="171" width="9" height="9" fill="#5e412c"/>
    <rect x="45" y="171" width="9" height="9" fill="#5e412c"/>
    <rect x="54" y="171" width="9" height="9" fill="#5e412c"/>
    <rect x="36" y="162" width="9" height="9" fill="#5e412c"/>
    <rect x="45" y="162" width="9" height="9" fill="#5e412c"/>
    <rect x="54" y="162" width="9" height="9" fill="#5e412c"/>
    <rect x="63" y="171" width="9" height="9" fill="#5e412c"/>
    <rect x="63" y="162" width="9" height="9" fill="#5e412c"/>
    <rect x="72" y="162" width="9" height="9" fill="#5e412c"/>
    <rect x="81" y="162" width="9" height="9" fill="#5e412c"/>
    <rect x="90" y="162" width="9" height="9" fill="#5e412c"/>
    <rect x="90" y="171" width="9" height="9" fill="#5e412c"/>
    <rect x="72" y="180" width="9" height="9" fill="#5e412c"/>
    <rect x="99" y="162" width="9" height="9" fill="#5e412c"/>
    <rect x="36" y="153" width="9" height="9" fill="#5e412c"/>
    <rect x="45" y="153" width="9" height="9" fill="#5e412c"/>
    <rect x="54" y="153" width="9" height="9" fill="#5e412c"/>
    <rect x="63" y="153" width="9" height="9" fill="#5e412c"/>
    <rect x="72" y="153" width="9" height="9" fill="#5e412c"/>
    <rect x="81" y="153" width="9" height="9" fill="#5e412c"/>
    <rect x="90" y="153" width="9" height="9" fill="#5e412c"/>
    <rect x="99" y="153" width="9" height="9" fill="#5e412c"/>
    <rect x="108" y="153" width="9" height="9" fill="#5e412c"/>
    <rect x="108" y="144" width="9" height="9" fill="#5e412c"/>
    <rect x="108" y="135" width="9" height="9" fill="#5e412c"/>
    <rect x="117" y="135" width="9" height="9" fill="#5e412c"/>
    <rect x="117" y="126" width="9" height="9" fill="#5e412c"/>
    <rect x="117" y="117" width="9" height="9" fill="#5e412c"/>
    <rect x="117" y="108" width="9" height="9" fill="#5e412c"/>
    <rect x="117" y="99" width="9" height="9" fill="#5e412c"/>
    <rect x="117" y="90" width="9" height="9" fill="#5e412c"/>
    <rect x="126" y="90" width="9" height="9" fill="#5e412c"/>
    <rect x="126" y="81" width="9" height="9" fill="#5e412c"/>
    <rect x="117" y="81" width="9" height="9" fill="#5e412c"/>
    <rect x="117" y="72" width="9" height="9" fill="#5e412c"/>
    <rect x="108" y="72" width="9" height="9" fill="#5e412c"/>
    <rect x="99" y="63" width="9" height="9" fill="#5e412c"/>
    <rect x="108" y="63" width="9" height="9" fill="#5e412c"/>
    <rect x="117" y="63" width="9" height="9" fill="#5e412c"/>
    <rect x="126" y="63" width="9" height="9" fill="#5e412c"/>
    <rect x="135" y="63" width="9" height="9" fill="#5e412c"/>
    <rect x="135" y="54" width="9" height="9" fill="#5e412c"/>
    <rect x="90" y="54" width="45" height="9" fill="#5e412c"/>
    <rect x="81" y="45" width="54" height="9" fill="#5e412c"/>
    <rect x="36" y="36" width="99" height="9" fill="#5e412c"/>
    <rect x="36" y="27" width="90" height="9" fill="#5e412c"/>
    <rect x="108" y="18" width="9" height="9" fill="#5e412c"/>
    <rect x="99" y="9" width="9" height="9" fill="#5e412c"/>
    <rect x="90" y="9" width="9" height="9" fill="#5e412c"/>
    <rect x="81" y="9" width="9" height="9" fill="#5e412c"/>
    <rect x="72" y="9" width="9" height="9" fill="#5e412c"/>
    <rect x="72" width="9" height="9" fill="#5e412c"/>
    <rect x="63" width="9" height="9" fill="#5e412c"/>
    <rect x="63" y="9" width="9" height="9" fill="#5e412c"/>
    <rect x="54" y="9" width="9" height="9" fill="#5e412c"/>
    <rect x="45" y="9" width="9" height="9" fill="#5e412c"/>
    <rect x="36" y="9" width="9" height="9" fill="#5e412c"/>
    <rect x="36" y="18" width="72" height="9" fill="#5e412c"/>
    <rect x="27" y="18" width="9" height="9" fill="#5e412c"/>
    <rect x="27" y="27" width="9" height="9" fill="#5e412c"/>
    <rect x="27" y="36" width="9" height="45" fill="#5e412c"/>
    <rect x="18" y="27" width="9" height="9" fill="#5e412c"/>
    <rect x="18" y="36" width="9" height="9" fill="#5e412c"/>
    <rect x="9" y="36" width="9" height="9" fill="#5e412c"/>
    <rect x="9" y="45" width="9" height="9" fill="#5e412c"/>
    <rect x="9" y="54" width="9" height="9" fill="#5e412c"/>
    <rect y="54" width="9" height="9" fill="#5e412c"/>
    <rect y="63" width="9" height="9" fill="#5e412c"/>
    <rect x="9" y="63" width="9" height="9" fill="#5e412c"/>
    <rect x="9" y="72" width="9" height="9" fill="#5e412c"/>
    <rect x="18" y="72" width="9" height="9" fill="#5e412c"/>
    <rect x="18" y="63" width="9" height="9" fill="#5e412c"/>
    <rect x="18" y="54" width="9" height="9" fill="#5e412c"/>
    <rect x="18" y="45" width="9" height="9" fill="#5e412c"/>
    <rect x="9" y="81" width="9" height="9" fill="#5e412c"/>
    <rect y="72" width="9" height="9" fill="#5e412c"/>
    <rect y="81" width="9" height="9" fill="#5e412c"/>
    <rect x="9" y="90" width="9" height="9" fill="#5e412c"/>
    <rect x="9" y="99" width="9" height="9" fill="#5e412c"/>
    <rect x="9" y="108" width="9" height="9" fill="#5e412c"/>
    <rect x="18" y="117" width="9" height="9" fill="#5e412c"/>
    <rect x="18" y="126" width="9" height="9" fill="#5e412c"/>
    <rect x="18" y="135" width="9" height="9" fill="#5e412c"/>
    <rect x="81" width="9" height="9" fill="#5e412c"/>
    <rect x="90" y="63" width="9" height="9" fill="#5e412c"/>
    <rect x="126" y="72" width="9" height="9" fill="#5e412c"/>
    <rect x="135" y="72" width="9" height="9" fill="#5e412c"/>
    <rect x="135" y="81" width="9" height="9" fill="#5e412c"/>
    <rect x="90" y="144" width="9" height="9" fill="#5e412c"/>
    <rect x="90" y="135" width="9" height="9" fill="#5e412c"/>
    <rect x="81" y="135" width="9" height="9" fill="#5e412c"/>
    <rect x="72" y="135" width="9" height="9" fill="#5e412c"/>
    <rect x="63" y="135" width="9" height="9" fill="#5e412c"/>
    <rect x="54" y="135" width="9" height="9" fill="#5e412c"/>
    <rect x="45" y="135" width="9" height="9" fill="#5e412c"/>
    <rect x="36" y="135" width="9" height="9" fill="#5e412c"/>
    <rect x="36" y="144" width="54" height="9" fill="#5e412c"/>
    <rect x="27" y="144" width="9" height="9" fill="#5e412c"/>
    <rect x="27" y="153" width="9" height="9" fill="#5e412c"/>
    <g id="FringeRight">
      <rect x="72" y="45" width="9" height="9" fill="#5e412c"/>
      <rect x="72" y="54" width="9" height="9" fill="#5e412c"/>
      <rect x="72" y="63" width="9" height="9" fill="#5e412c"/>
    </g>
    <rect x="36" y="45" width="9" height="9" fill="#5e412c"/>
    <rect x="45" y="45" width="9" height="9" fill="#5e412c"/>
    <rect x="36" y="54" width="9" height="9" fill="#5e412c"/>
    <rect x="36" y="63" width="9" height="9" fill="#5e412c"/>
    <g id="FringeLeft">
      <rect x="59" y="45" width="9" height="9" fill="#5e412c"/>
      <rect x="57" y="54" width="9" height="9" fill="#5e412c"/>
      <rect x="55" y="63" width="9" height="9" fill="#5e412c"/>
    </g>
    <rect x="99" y="144" width="9" height="9" fill="#ffc49c"/>
    <rect x="99" y="135" width="9" height="9" fill="#ffc49c"/>
    <rect x="99" y="90" width="9" height="45" fill="#ffc49c"/>
    <rect x="90" y="99" width="9" height="36" fill="#ffc49c"/>
    <rect x="81" y="99" width="9" height="36" fill="#ffc49c"/>
    <rect x="72" y="126" width="9" height="9" fill="#ffc49c"/>
    <rect x="63" y="126" width="9" height="9" fill="#ffc49c"/>
    <rect x="54" y="126" width="9" height="9" fill="#ffc49c"/>
    <rect x="45" y="126" width="9" height="9" fill="#ffc49c"/>
    <rect x="36" y="126" width="9" height="9" fill="#ffc49c"/>
    <rect x="27" y="126" width="9" height="9" fill="#ffc49c"/>
    <rect x="27" y="135" width="9" height="9" fill="#ffc49c"/>
    <rect x="108" y="81" width="9" height="54" fill="#ffc49c"/>
    <rect x="18" y="99" width="9" height="18" fill="#ffc49c"/>
    <rect x="27" y="108" width="54" height="9" fill="#ffc49c"/>
    <rect x="45" y="99" width="36" height="9" fill="#ffc49c"/>
    <rect x="27" y="117" width="18" height="9" fill="#ffc49c"/>
    <rect x="54" y="90" width="18" height="9" fill="#ffc49c"/>
    <rect x="36" y="72" width="72" height="9" fill="#ffc49c"/>
    <rect x="81" y="54" width="9" height="18" fill="#ffc49c"/>
    <rect x="45" y="63" width="9" height="9" fill="#ffc49c"/>
    <rect x="45" y="54" width="9" height="9" fill="#ffc49c"/>
    <rect x="54" y="63" width="1" height="9" fill="#ffc49c"/>
    <rect x="54" y="54" width="3" height="9" fill="#ffc49c"/>
    <rect x="54" y="45" width="5" height="9" fill="#ffc49c"/>
    <rect x="67" y="45" width="5" height="9" fill="#ffc49c"/>
    <rect x="66" y="54" width="6" height="9" fill="#ffc49c"/>
    <rect x="64" y="63" width="8" height="9" fill="#ffc49c"/>
    <rect x="90" y="87" width="9" height="13" fill="#ffc49c"/>
    <rect x="63" y="81" width="45" height="9" fill="#ffc49c"/>
    <rect x="54" y="81" width="9" height="9" fill="#ffc49c"/>
    <rect x="52" y="81" width="2" height="9" fill="#ffc49c"/>
    <rect x="18" y="81" width="34" height="18" fill="#ffc49c"/>
    <rect x="23" y="86" width="4" height="1" fill="#ffc49c"/>
    <rect x="18" y="86" width="5" height="4" fill="#ffc49c"/>
    <rect x="18" y="90" width="2" height="9" fill="#ffc49c"/>
    <rect x="20" y="96" width="7" height="3" fill="#ffc49c"/>
    <rect x="52" y="90" width="2" height="9" fill="#ffc49c"/>
    <rect x="45" y="90" width="7" height="5" fill="#ffc49c"/>
    <rect x="36" y="90" width="9" height="4" fill="#ffc49c"/>
    <rect x="27" y="94" width="11" height="2" fill="#ffc49c"/>
    <rect x="30" y="91" width="6" height="3" fill="#ffc49c"/>
    <rect x="27" y="96" width="18" height="12" fill="#ffc49c"/>
    <rect x="27" y="96" width="5" height="5" fill="#ffc49c"/>
    <rect x="72" y="81" width="9" height="1" fill="#ffc49c"/>
    <rect x="72" y="86" width="18" height="13" fill="#ffc49c"/>
    <g id="Eyes">
      <g>
        <rect x="40" y="92" width="7" height="7" fill="#5e5f2c"/>
        <rect x="47" y="92" width="7" height="7" fill="#fff"/>
        <rect x="33" y="92" width="7" height="7" fill="#fff"/>
      </g>
      <g>
        <rect x="88" y="92" width="7" height="7" fill="#5e5f2c"/>
        <rect x="95" y="92" width="7" height="7" fill="#fff"/>
        <rect x="81" y="92" width="7" height="7" fill="#fff"/>
      </g>
    </g>
    <rect x="45" y="117" width="36" height="9" fill="#ffc49c"/>
    <g id="Mouth">
      <rect x="49" y="146" width="39" height="6" fill="#ffa09c"/>
      <rect x="51" y="143" width="39" height="6" fill="#ffa09c"/>
      <rect x="47" y="143" width="39" height="6" fill="#ffa09c"/>
    </g>
    <g id="Eyebrows">
      <rect x="30" y="82" width="24" height="4" fill="#5e412c"/>
      <rect x="81" y="82" width="24" height="4" fill="#5e412c"/>
    </g>
    <g id="Nose">
      <rect x="68" y="118" width="14" height="4" transform="translate(195 45) rotate(90)" fill="#5e412c" opacity="0.24"/>
      <rect x="67" y="121" width="5" height="7" transform="translate(194 55) rotate(90)" fill="#5e412c" opacity="0.24"/>
      <rect x="64" y="104" width="14" height="4" transform="translate(177 35) rotate(90)" fill="#5e412c" opacity="0.24"/>
    </g>
  </svg>

      </figure>
      <span><small>&copy; Nathan Akrill</small></span>
    </Layout>
  )
}

export default IndexPage
